#navbar {
    display: flex;
    z-index: 1;
    background-color: #1D1D1D;
    color: #A98F4E;
}

/* iPads (portrait and landscape) ----------- */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1080px) {
    #login-page {
        width: 80%;
    }
} */


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #navbar {
        min-height: 430vh;
    }
  }
