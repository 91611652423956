.image-slideshow {
    display: flex;
    justify-content: center;
     /* Change height to auto */
    /* max-height: 600px; */
}

/* .ant-carousel {
    width: 100%;
    height: 100%;
    } */


  .image {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }



.ant-card-head-title {
    /* color: rgba(0, 0, 0, 0.45); */
    color: #A98F4E;
}

.ant-picker-range {
    margin-bottom: 3%;
}

p {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 3%;
}

.card-body {
    flex-wrap: wrap;
}

.sold-units-value-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: auto;
}

/* @media (max-width: 768px) {
    .ant-carousel {
        height: 300px; 
    }
} */
 
.announcements-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .announcement {
    margin: 10px;
  }
  
  .announcement-image {
    width: 300px; 
    height: 200px; 
    object-fit: cover; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  