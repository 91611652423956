.profile-page {
    display: flex;
    justify-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  
  
  .left-section {
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 100px;
  }
  
  
  .right-section {
    flex: 2; 
    padding: 20px;
    
  }
  
  
  .left-section input[type="file"] {
    margin-bottom: 10px;
  }
  
  
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; 
  }
  
  .profile {
    text-align: center;
  }
  
  .profile-form {
    width: 400px;
    margin: 0 auto; 
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-form label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .profile-form input[type="text"],
  .profile-form input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .profile-form button {
    width: 100%;
    padding: 12px;
    background-color: #A98F4E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .profile-page {
    display: block;
    margin-left: 0%;
    margin-right: 0%;
    width: 50%;
    padding: 100px 40;
  }
  
  .profile-container {
    text-align: center;
  }
  
  .profile {
    display: inline-block;
    text-align: left;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
  }
  
  .profile-header {
    font-size: 30px;
    font-weight: bold;
    color: #A98F4E;
  }
  
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .user-card {
    width: 500px; 
  }
  
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .profile-container {
    display: flex;
    align-items: center; 
  }
  
  .profile-details {
    margin-left: 200px; 
    margin-top: 10px;
  }
  
  .sales-header {
    font-size: 24px;
    font-weight: bold;
    color: #A98F4E;
  }

  .crud-section {
  text-align: center; 
  }

  .sales-table-container {
  margin: 0 auto; 
  }
