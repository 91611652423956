@tailwind base;
@tailwind components;
@tailwind utilities;

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5vh;
  align-items: baseline;
  flex-grow: 4;
  height: 100%;
  width: 100%;
}

header h1 {
  font-size: 30px;
  font-weight: 700;
  /* color: #A98F4E; */
}

header p {
  color: #A98F4E; 
}

#page {
    /* margin: 1.8%; */
    width: 100%;
    height: 100%;
    overflow: visible;
}

#page h2 {
  margin-bottom: 2%;
}

#whole {
    display: flex;
    height: 100%;
    width: 100%;
    /* justify-content: space-around; */
}

#user-box {
  display: flex;
  justify-content: space-between;
  gap: 10%;
  width: fit-content;
}

#header-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15%;
  width: 100%;
  margin-right: 10%;
  align-items: flex-start;

}

.ant-btn-primary:not([disabled]) {
    background:#A98F4E !important;
  }

.ant-btn-primary:not([disabled]):hover {
    background:#A98F4E !important;
  }

.ant-btn-default:disabled {
  background-color:#A98F4E !important;
}

#notFoundBody {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 28px;
      /* font-weight: bold; */
      margin: 1.8%;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow: visible;

}

#body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    /* font-weight: bold; */
    color: #A98F4E;
    margin: 1.8%;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow: visible;
}

#user{
  display: flex;
  color: #A98F4E;
  /* text-align: center; */
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 4;
  align-items: center;
  min-width: 80px;
}

#user-name{
  font-size: small;
  text-align: center;
}

#user-role{
  text-align: center;
  font-size: x-small;
}

#start-end{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1%;
}

#seller-cards{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ant-card{
  margin-bottom: 1%;
}

.custom-toast-body {
  font-size: 14px;
  padding: 10px 15px;
}


.custom-toast {
  width: 300px;
}

#header-box {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
  align-items: center   ;
}

#header-points {
  font-size: small;
  flex-wrap: nowrap;
  flex-grow: 6;
  min-width: 50px;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  text-align: center;
  margin-top: 4.7%
}

#header-points p {
  margin: 0; 
  font-weight: 600;
}

#notification-modal {
  height: 300px; 
  overflow: auto;
}

#map-container {
  width: 100%;
  height: 600px;
}

.text-gold-h2{
  font-size: 20px;
  font-weight: 700;
  color: #A98F4E;
}


@media only screen and (max-width: 600px) {
  #body {
    width: 150% !important;
    height: 150% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #whole {
      min-height: 330vh;
  }
}
