.header {
    text-align: center;
}

.head {
    text-align: center;
    font-weight: bold;
    color: #A98F4E;
    font-size: 30px;
    margin: 2.8%;
    border: 1px solid #A98F4E;
    border-radius: 10px;
}

.course-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.search-container {
    text-align: center;
    margin: 20px auto; 
    width: 70%; 
}

.user-cards-container {
    display: flex;
    justify-content: center;
}

.user-cards {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    gap: 20px; 
    max-width: 1000px; 
}

.image-slideshow {
    display: flex;
    justify-content: center;
}

.ant-image   {
    display: flex;
    justify-content: center;
    margin: auto;
}